<template>
    <div id="app">
        <!-- 日历 -->
        <div class="bg_green">
            <div class="white_box3">
                <!-- 标题 -->
                <div class="orange_title" style="font-size:16px; font-weight: normal;">{{rq}}</div>
                <div class="white_box3_in pdb_0">
                    <!-- 列表 -->
                    <div class="user under_line pd_3p6_0 clear" v-for="(option,index) in reservationRecord"
                         :key="index">
                        <div class="user_msg">
                            <span>{{option.productBundle.name}}</span>
                            <label v-if="option.type==='ENROLL'">报餐</label>
                            <label v-if="option.type==='STOP'">停餐</label>
                            <label v-if="option.type==='CHANGE'">换餐</label>
                        </div>
                        <div class="user_r right_0 bottom_3p6">
                            {{option.productBundle.singlePrice.toFixed(2)}}
                        </div>
                    </div>
                    <!-- 合集 -->
                    <div class="total_count" style="font-weight: normal;">合计：{{totalPrice.toFixed(2)}}元</div>
                </div>
            </div>
        </div>

        <!-- 按钮 -->
        <div class="white_box3 mgt_3vw">
            <div class="white_box3_in pdb_0">
                <div class="tree_btn">
                    <ul class="clear">
                        <li>
                            <router-link to="reservation_stop">
                                <button class="button_green">停餐</button>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="reservation_enroll">
                                <button class="button_green_line">终止停餐</button>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="reservation_change">
                                <button class="button_green_line">换/加餐</button>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="loading_all" v-if="loading">
            <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
        </div>

    </div>
</template>


<style scoped>

</style>

<script>
    import Vue from 'vue'
    import {ACCESS_TOKEN, ACCESS_CustomerUser} from '@/store/mutation-types'
    import {post} from '@/utils/http'
    import {Dialog} from 'vant';

    export default {
        data() {
            return {
                rq: null,
                customerUser: {},
                reservationRecord: [],
                totalPrice: 0,
                loading: false
            };
        },
        mounted() {
            this.rq = this.$route.query.rq;
            this.customerUser = Vue.ls.get(ACCESS_CustomerUser)

            this.loading = true
            post('api/reservation/v2/find/between', {
                businessCustomerUserId: this.customerUser.businessCustomerUserId,
                startDay: this.rq,
                endDay: this.rq
            }).then(res => {
                this.reservationRecord = res.data;
                this.reservationRecord.forEach(item => this.totalPrice += item.productBundle.singlePrice)
                this.loading = false
            }).catch(() => this.loading = false)
        },
        methods: {}
    };
</script>
